export const Page = {
  Login: () => import('pages/Login/LoginSSO'),
  LoginMultiStep: () => import('pages/Login/LoginMultiStep'),
  LoginUpgrade: () => import('pages/Login/LoginUpgrade'),
  VerifyEmail: () => import('pages/2FA/VerifyEmail'),
  TwoFactorAuthentication: () => import('pages/2FA/2FA'),
  PasswordSecurityUpgrade: () => import('pages/PasswordUpgrade'),
  ChangePassword: () => import('pages/ResetPassword/ChangePassword'),
  OAuthDropOf: () => import('pages/OAuthDropOf'),
  SignupCreateAccount: () => import('pages/SignUp/CreateAccount'),
  SignUpPaymentCheckout: () => import('pages/SignUp/PaymentCheckout'),
  InviteSetPassword: () => import('pages/Invite/SetPassword'),
  SendResetEmail: () => import('pages/ResetPassword/SendResetEmail'),
  TwoFactorAuthenticationUnenroll: () => import('pages/2FA/Unenroll'),
  AuthRedirect: () => import('pages/AuthRedirect'),
  InternalError: () => import('pages/InternalError'),
  InviteSetAdminUserInfo: () => import('pages/Invite/SetAdminUserInfo'),
  SetupManager: () => import('pages/SignUp/SetupManager'),
  SetupOrganization: () => import('pages/SignUp/SetupOrganization'),
  SetupWaiting: () => import('pages/SignUp/SetupWaiting'),
  InvalidSubscription: () => import('pages/InvalidSubscription'),
  Videos: () => import('pages/Videos'),
  StoryMakers: () => import('pages/Storymakers'),
  Reviewers: () => import('pages/Reviewers'),
  FilmingGroups: () => import('pages/FilmingGroups'),
  TemplateDetail: () => import('pages/FilmingGroups/TemplateDetail'),
  SettingsBrand: () => import('pages/Settings/Brand'),
  SettingsSubscription: () => import('pages/Settings/Subscription'),
  Organizations: () => import('pages/Organizations'),
  OrganizationDetailDetails: () => import('pages/Organizations/OrganizationDetail/Details'),
  OrganizationDetailFilmingGroups: () =>
    import('pages/Organizations/OrganizationDetail/FilmingGroups'),
  OrganizationDetailFilmingGroupCreate: () =>
    import('pages/Organizations/OrganizationDetail/FilmingGroups/CreateFilmingGroup'),
  AdminUsers: () => import('pages/AdminUsers'),
  SettingsOrganization: () => import('pages/Settings/Organization'),
  SettingsAdminUsers: () => import('pages/Settings/AdminUsers'),
  AdminUserDetail: () => import('pages/AdminUsers/AdminUserDetail'),
  StorymakerDetail: () => import('pages/Storymakers/StorymakerDetail'),
  TemplateDetailAdminDetails: () => import('pages/Templates/TemplateDetailAdmin/Details'),
  TemplateDetailAdminFilmingGuide: () => import('pages/Templates/TemplateDetailAdmin/FilmingGuide'),
  Templates: () => import('pages/Templates'),
  ExpiredPassword: () => import('pages/ExpiredPassword'),
  CreateVideo: () => import('pages/Videos/CreateVideo'),
  MediaDetail: () => import('pages/Videos/Detail'),
  Training: () => import('pages/Training'),
  NotFound: () => import('pages/NotFound'),
};
