import React, { useState, useCallback } from 'react';

import * as S from './styles';

type Props = {
  onClick: () => void;
  itemKey: string;
  icon?: { hovered: string; normal: string };
  text: string;
  disabled?: boolean;
  hoverColor?: Colors;
  afterLine?: boolean;
  capitalCase?: boolean;
  loading?: boolean;
  [key: string]: any;
  Wrapper?: React.ComponentType<any>;
};

const DropdownMenuItem: React.FC<Props> = ({
  onClick,
  capitalCase,
  itemKey,
  icon,
  text,
  disabled,
  hoverColor,
  afterLine,
  loading = false,
  Wrapper,
  ...props
}) => {
  const [hovered, setHovered] = useState(false);

  const onPointerOver = useCallback(() => setHovered(true), []);
  const onPointerLeave = useCallback(() => setHovered(false), []);

  const body = (
    <S.BodyWrap style={{ textTransform: capitalCase ? 'capitalize' : 'uppercase' }}>
      {loading && <S.Spinner size="small" />}
      {icon && (
        <S.MenuItemIcon
          src={hovered ? icon.hovered : icon.normal}
          alt={`Dropdown menu icon for ${text} item`}
        />
      )}
      {text}
    </S.BodyWrap>
  );

  return (
    <S.MenuItem
      {...props}
      onClick={onClick}
      key={itemKey}
      onPointerOver={onPointerOver}
      onPointerLeave={onPointerLeave}
      disabled={disabled || loading}
      data-testid={`dropdownMenuItem-${itemKey}`}
      $hoverColor={hoverColor || 'lightBlue'}
      $afterLine={afterLine}
    >
      {Wrapper ? <Wrapper>{body}</Wrapper> : body}
    </S.MenuItem>
  );
};

export default DropdownMenuItem;
