// import * as Sentry from '@sentry/browser';
// import { getEnvVar, tryGetEnvVar } from 'utils/env';
import { SentryBreadcrumbLink } from './apolloLink';

export const init = () => {
  // const SENTRY_ENABLED = tryGetEnvVar('SENTRY_DISABLED') !== 'true';

  // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
  // const ignoreErrors = [
  //   'ResizeObserver loop limit exceeded',
  //   'Not Authorised!',
  //   /too many connections for role/,
  // ];

  // if (SENTRY_ENABLED) {
  //   Sentry.init({
  //     ignoreErrors,
  //     dsn: getEnvVar('SENTRY_DSN'),
  //     environment: getEnvVar('SENTRY_ENVIRONMENT'),
  //     release: getEnvVar('SENTRY_RELEASE'),
  //   });
  // } else {
  //   Sentry.init({ enabled: false });
  // }
};

export const apolloSentryLink = new SentryBreadcrumbLink();
