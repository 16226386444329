export default {
  colors: {
    orange: '#FC7411',
    darkBlue: process.env.REACT_APP_SENTRY_ENVIRONMENT === 'Staging' ? '#01222b' : '#144154',
    blue: '#6D8996',
    lightBlue: '#5995B2',
    darkGrey: '#63656A',
    grey: 'rgba(99, 101, 106, 0.3)',
    lightGrey: '#eff0f0',
    lightestGrey: '#f3f3f3',
    silver: '#D0D1D2',
    black: '#404041',
    darkBlack: '#000000',
    white: '#FFFFFF',
    offWhite: '#F5F5F5',
    green: '#3BC974',
    darkGreen: '#06A94D',
    red: '#DC3434',
    shadowBlack: '#07212C',
    disabledOrange: '#FDAC70',

    // Status Tags backgrounds:
    bgGreen: '#ceeedc',
    bgRed: '#f8d6d6',
    bgBlue: '#deeaf1',
    bgOrange: '#fee3cf',
    bgGrey: '#e0e0e1',
    bgYellow: '#FFEF99',
  },
  sizes: {
    sideBarWidthPx: 208,
    headerHeightPx: 64,
    subHeaderHeightPx: 40,
    mainContentPaddingPx: 24,

    // tables
    pageWithTableHeaderHeightPx: 38,
    tableHeadHeightPx: 48,
    tableBaseRowHeight: 56,
    videoTableBaseRowHeight: 72,
    tableFooterHeightPx: 56,

    smallScreenLimitPx: 1024,
  },
  fontSizes: {
    h1: '40px',
    h2: '30px',
    h3: '18px',

    xxsm: '10px',
    xsm: '12px',
    sm: '14px',
    md: '16px',
    lg: '18px',
    xlg: '34px',
    xxlg: '48px',
  },
  fontWeights: {
    regular: 400,
    medium: 600,
    bold: 700,
  },
  lineHeights: {
    xsm: '16px',
    sm: '20px',
    md: '24px',
    lg: '30px',
    xlg: '34px',
    xxlg: '48px',
  },
  spacings: {
    xsm: '4px',
    sm: '8px',
    md: '16px',
    lg: '24px',
    xlg: '32px',
    xxlg: '40px',
  },
  radius: {
    xsm: '4px',
    sm: '5px',
    md: '10px',
    lg: '15px',
    xlg: '20px',
  },
  devices: {
    wideScreen: '(orientation: landscape) and (min-width: 1200px)',
    thirteenInch: '(orientation: landscape) and (max-width: 1500px)',
    mobile: 'screen and (max-width: 1024px)',
    desktop: 'screen and (min-width: 1024px)',
    landscape: 'screen and (orientation: landscape)',
    shortScreen: '(orientation: landscape) and (max-height: 720px)',
  },
} as const;
