import React, { useRef } from 'react';
import { Dropdown as AntdDropdown } from 'antd';
import { DropDownProps } from 'antd/es/dropdown';

import * as S from './styles';

type Props = { className?: string } & DropDownProps;

const Dropdown: React.FC<Props> = (props) => {
  const wrapRef = useRef<HTMLDivElement>(null);

  return (
    <S.Wrap ref={wrapRef} className={props.className} onClick={(e) => { e.stopPropagation() }}>
      <AntdDropdown
        {...props}
        getPopupContainer={() => wrapRef.current as HTMLDivElement}
        overlayClassName="dropdown"
      >
        {props.children}
      </AntdDropdown>
    </S.Wrap>
  );
};

export default Dropdown;
