import { ApolloClient, split, from, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { WebSocketLink } from '@apollo/client/link/ws';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { getMainDefinition } from '@apollo/client/utilities';

import { getEnvVar } from 'utils/env';
import { apolloSentryLink } from 'lib/sentry';

import customFetch from './customFetch';
import { errorLink } from './error';
import { authLink } from './auth';

import introspectionQueryResultData from 'codegen/fragmentTypes.json';

const uri = getEnvVar('GRAPHQL_API');
const wsUri = getEnvVar('GRAPHQL_API_WS');

const wsLink = new WebSocketLink(new SubscriptionClient(wsUri, { reconnect: true }));

const httpLink = createUploadLink({
  uri,
  fetch: customFetch,
});

const batchHttpLink = new BatchHttpLink({ uri });

// FragmentMatcher, HeuristicFragmentMatcher, and IntrospectionFragmentMatcher have all been removed. We recommend using the InMemoryCache’s possibleTypes option instead. For more information, see Defining possibleTypes manually (PR #5073).
// https://github.com/apollographql/apollo-client/pull/5073

const batchHttpOrHttp = split((operation) => operation.getContext().batch, batchHttpLink, httpLink);

const finalSplitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  batchHttpOrHttp
);

export default new ApolloClient({
  cache: new InMemoryCache({
    possibleTypes: introspectionQueryResultData.possibleTypes,
  }),
  link: from([apolloSentryLink, errorLink, authLink, finalSplitLink]),
  defaultOptions: {
    query: {
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
});
