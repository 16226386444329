import React, { useMemo } from 'react';

import Dropdown from 'components/Dropdown';

import DropdownMenuItemComponent from './DropdownMenuItem';
import * as S from './styles';

type Props = {
  menuItems: DropdownMenuItem[];
  capitalCase?: boolean;
} & Omit<ComponentProps<typeof Dropdown>, 'overlay'>;

const DropdownMenu: React.FC<Props> = ({ menuItems, capitalCase, children, ...props }) => {
  const menu = useMemo(
    () => (
      <S.Menu onClick={(e) => e.domEvent.stopPropagation()}>
        {menuItems.map(({ hoverColor, ...props }) => (
          <DropdownMenuItemComponent
            key={props.itemKey}
            {...props}
            hoverColor={hoverColor}
            capitalCase={capitalCase}
          />
        ))}
      </S.Menu>
    ),
    [capitalCase, menuItems]
  );

  return (
    <Dropdown overlay={menu} {...props} trigger={props.trigger || ['click']}>
      {children}
    </Dropdown>
  );
};

export default DropdownMenu;
